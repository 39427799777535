import {
    Navbar,
    Admin,
    MyWallet,
    Marketplace,
    ImportToGame,
    PoolList,
} from './components'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
    return (
        <Router>
            <div className="App h-max p-0 md:p-2 relative">
                <Navbar />

                <Routes>
                    <Route path="/" element={<Marketplace />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/Marketplace" element={<Marketplace />} />
                    <Route path="/MyWallet" element={<MyWallet />} />
                    <Route path="/ImportToGame" element={<ImportToGame />} />
                    <Route path="/PoolList" element={<PoolList />} />
                </Routes>
            </div>
        </Router>
    )
}

export default App
